export default {
  getTotem(state) {
    return state.totem;
  },
  getToken(state) {
    return state.token;
  },
  getErros(state) {
    return state.erros;
  },
  getAFK(state) {
    return state.afk;
  },
};
