const etapa = {
  TIPO_FOTO: "TIPO_FOTO",
  QTDE_FOTO: "QTDE_FOTO",
  SCAN_QR_CODE: "SCAN_QR_CODE",
  UPLOAD: "UPLOAD",
  EDICAO: "EDICAO",
  PAGAMENTO_SELECT: "PAGAMENTO_SELECT",
  PAGAMENTO_PROCESSO: "PAGAMENTO_PROCESSO",
  PAGAMENTO_FALHOU: "PAGAMENTO_FALHOU",
  PAGAMENTO_SUCESSO: "PAGAMENTO_SUCESSO",
  IMPRESSAO: "IMPRESSAO",
  AGRADECIMENTO: "AGRADECIMENTO",
  CONCLUIDO: "CONCLUIDO",
  EXPIROU: "EXPIROU",
  CANCELADA: "CANCELADA",
};
export default etapa;
