export default {
  FAST: 1,
  TIME_MODAL: 30,
  TIPO_FOTO: 60, // 60
  QTDE_FOTO: 60,
  SCAN_QR_CODE: 60,
  UPLOAD: 120,
  EDICAO: 120,
  PAGAMENTO_PROCESSO: 120,
  PAGAMENTO_SELECT: 120,
  PAGAMENTO_FALHOU: 120,
  IMPRESSAO_FALHA: 120,
};
